/* eslint-disable react/prop-types */
import { Typography, Box, MenuItem, TextField, Button } from '@mui/material'
import { useState } from 'react';
import { Close, Add, Edit, Save } from '@mui/icons-material';
import { Formik } from 'formik'
import { css, jsx } from '@emotion/react'
/** @jsx jsx */
const addListingStyle = css`
  display:flex;
  width:100%;
  margin-top:10px;
  justify-content:space-between;
  &>div{
    display:flex;
	flex: 1;
	flex-basis: 100%;
	flex-grow: 0;
    flex-direction:column;
    align-items:center;
  };
  &>div>*{
	width:100%;
  }

`
const ListingEntry = (props) => {
	const [isEditing, setIsEditing] = useState(props.isEditing)

	const [displayDescFull, setDisplayDescFull] = useState(false)

	const [listingName, setListingName] = useState(props.listingName)
	const [listingData, setListingData] = useState(props.listingData)

	const [secondaryImgs, setSecondaryImgs] = useState(props.secondaryImgs)
	const [primaryImg, setPrimaryImg] = useState(props.primaryImg)

	const { handleDelete, saveChanges, handleValidate } = props

	let orgMode

	if (!props.isEditing) {
		orgMode = 'listing display'
	}
	else {
		orgMode = 'add field'
	}

	const handleFileUpload = (e, setFieldValue, field) => {
		let files = e.target.files
		let filesList = []
		for (let i = 0; i < files.length; i++) {

			let file = files[i]
			let reader = new FileReader();

			reader.readAsDataURL(file);

			reader.onload = () => {
				filesList.push({
					content: reader.result,
					name: file.name
				})

				if (field === 'primaryImg') {
					filesList = filesList[0]
				}

				if (filesList.length == files.length || field === 'primaryImg') {
					setFieldValue(field, filesList)
				}
			};
		}
	}
	const handleFileDelete = (values, field, file, setFieldValue) => {

		let files = values[field]

		if (field === 'primaryImg') {
			files = [files]
		}
		for (let i = 0; i < files.length; i++) {


			if (files[i].name === file.name) {
				files.splice(i, 1)
				setFieldValue(field, files)
				break
			}
		}
	}
	if (isEditing) {
		return (<Formik
			initialValues={{
				brand: listingData.brand,
				listingName: listingName,
				price: listingData.price,
				itemType: listingData.itemType,
				featured: listingData.featured,
				description: listingData.description,
				oldPrice: listingData.oldPrice,
				secondaryImgs: [...secondaryImgs],
				primaryImg: primaryImg,
			}}

			validate={(values) => {
				return handleValidate(values, listingName)

			}}

			onSubmit={async (values, form) => {

				let newListing = await saveChanges(values, orgMode, listingData.secondaryImgNames.concat([listingData.primaryImgName]), listingName, form.setFieldValue)

				if (orgMode == 'listing display') {

					setListingName(newListing.listingName)

					setSecondaryImgs(newListing.secondaryImgs)

					setPrimaryImg(newListing.primaryImg)

					setListingData(newListing.data)
				}
				else {
					setListingName('')

					setSecondaryImgs([])

					setPrimaryImg({})

					setListingData({
						brand: '',
						price: null,
						oldPrice: null,
						itemType: '',
						featured: false,
						description: '',
						primaryImgName: '',
						secondaryImgNames: []
					})
				}
			}}
		>
			{({ errors, touched, values, handleChange, handleSubmit, setFieldValue }) => (
				<Box>
					<form onSubmit={handleSubmit} style={{ display: "flex", borderBottom:"1px solid black" }}>
						<Box css={addListingStyle}>
							<Box>
								<TextField value={values.listingName} name="listingName" onChange={handleChange} />
								{errors.listingName && touched.listingName ?
									<Typography style={{ color: "red" }}>{errors.listingName}</Typography>
									: null}
							</Box>

							<Box>
								<TextField value={values.brand} name="brand" onChange={handleChange} />
								{errors.brand && touched.brand ?
									<Typography style={{ color: "red" }}>{errors.brand}</Typography>
									: null}
							</Box>

							<Box>
								<TextField type="number" value={values.price} name="price" onChange={handleChange} />
								{errors.price && touched.price ?
									<Typography style={{ color: "red" }}>{errors.price}</Typography>
									: null}
							</Box>

							<Box>
								<TextField type="number" value={values.oldPrice} name="oldPrice" onChange={handleChange} />
								{errors.oldPrice && touched.oldPrice ?
									<Typography style={{ color: "red" }}>{errors.oldPrice}</Typography>
									: null}
							</Box>

							<Box>

								<TextField select value={values.itemType} name="itemType" onChange={handleChange} type="text">
									{['Accessory', 'Amplifier', "Audio Cables", "Bookshelf Speaker", "Cassette Deck", "CD Player", "Center Speaker", "DAC", "Equalizer", "Floor Standing Speaker", "Power Amplifier",
										"Pre-Amplifier", "Pre-Amp & Power Amp Set", "Receiver", "Reel-to-reel Tape Recorder", "Speaker Wires", "Sub-Woofer", "Turntable", "Tuner", "Tuner & Amp Set"].map((itemType) => {
											return (<MenuItem key={itemType} value={itemType}>
												{itemType}
											</MenuItem>)
										})}

								</TextField>
								{errors.itemType && touched.itemType ?
									<Typography style={{ color: "red" }}>{errors.itemType}</Typography>
									: null}
							</Box>

							<Box>
								<TextField select value={values.featured} name="featured" onChange={handleChange} type="text">
									<MenuItem key="true" value={true} >
										True
									</MenuItem>
									<MenuItem key="false" value={false}>
										False
									</MenuItem>
								</TextField>
							</Box>

							<Box>

								<TextField value={values.description} name="description" multiline={true} inputProps={{
									maxLength: 600
								}} onChange={handleChange} />

								{errors.description && touched.description ?
									<Typography style={{ color: "red" }}>{errors.description}</Typography>
									: null}
							</Box>

							<Box style={{ display: "flex", flexDirection: "column" }}>
								<Box>
									<Button
										component="label"
										style={{ display: "flex" }}
									>
										Select images
										<input type="file"
											hidden
											multiple
											accept="image/png, image/jpeg, image/webp"
											onChange={
												(e) => {
													handleFileUpload(e, setFieldValue, 'secondaryImgs')
												}
											}
										/>
									</Button>
								</Box>

								<Box>
									{values.secondaryImgs.map((file) => {


										return (
											<Box key={file.name}>
												<img src={file.content} style={{ width: "100%" }} />
												<a href={file.content}>{file.name}</a>
												<Close
													style={{ cursor: "pointer" }}
													onClick={() => { handleFileDelete(values, 'secondaryImgs', file, setFieldValue) }}
												/>
											</Box>
										)
									})}

									{errors.secondaryImgs && touched.secondaryImgs ?
										typeof errors.secondaryImgs === 'string' ?
											<Typography style={{ color: "red" }}>{errors.secondaryImgs}</Typography>
											:
											errors.secondaryImgs.map((error, key) => {
												if (errors.secondaryImgs && touched.secondaryImgs) {
													return (<Typography key={key} style={{ color: "red" }}>{error}</Typography>)
												}
											})
										:
										null
									}

								</Box>
							</Box>
							<Box>
								<Button
									component="label"
									style={{ display: "flex" }}
								>
									Select images
									<input type="file"
										accept="image/png, image/jpeg, image/webp"
										hidden
										onChange={
											(e) => {
												handleFileUpload(e, setFieldValue, 'primaryImg')
											}
										}
									/>
								</Button>
								<Box>
									<img src={values.primaryImg.content} style={{ width: '100%' }} />
									<a href={values.primaryImg.content}>{values.primaryImg.name}</a>

									{values.primaryImg.name ?
										<Close
											style={{ cursor: "pointer" }}
											onClick={() => { handleFileDelete(values, 'primaryImg', values.primaryImg, setFieldValue) }}
										/>
										:
										null
									}
									{errors.primaryImg && touched.primaryImg ?
										<Typography style={{ color: "red" }}>{errors.primaryImg}</Typography>
										: null}

								</Box>
							</Box>
							<Box style={{ width: "9%", display: "flex", marginTop: "30px", alignItems: "flex-start" }}>
								<Button type="submit" width="9%">
									{orgMode == 'add field' ?
										<Add />
										:
										<Save />
									}
								</Button>
								{orgMode == 'listing display' ?
									<Edit
										style={{ cursor: "pointer" }}
										onClick={() => {
											setIsEditing(false)
										}} />
									:
									null
								}
							</Box>
						</Box>
					</form>
				</Box>
			)}
		</Formik>)
	}

	let descriptionDisplay = listingData.description
	let descHoverText = '"Click to compress text"'

	if (listingData.description.length > 150 && !displayDescFull) {
		descriptionDisplay = listingData.description.slice(0, 151) + "..."
		descHoverText = '"Click to expand text"'
	}

	return (
		<Box style={{ display: "flex", borderBottom: "1px solid black" }} id={listingName}>
			<Box
				sx={{
					display: "flex",
					alignItems: "center",
					width: "91%",
					'& p, div': {
						flex: 1,
						textAlign: "center",
						border: "1px solid white",
					},
				}}
			>
				<Typography style={{ borderLeft: "0px", borderBottom: "0px" }}>{listingName}</Typography>
				<Typography>{listingData.brand}</Typography>
				<Typography>{listingData.price}</Typography>
				<Typography>{listingData.oldPrice}</Typography>
				<Typography>{listingData.itemType}</Typography>
				<Typography>{listingData.featured.toString()}</Typography>

				{descriptionDisplay.length > 150 ?
					<Typography
						onClick={() => {
							setDisplayDescFull(!displayDescFull)
						}}
						sx={{
							cursor: "pointer",
							position: "relative",
							borderBottom: "1px dotted black",
							transitionDelay: "2s",
							"&:before": {
								content: descHoverText,
								visibility: "hidden",
								opacity: "0",
								fontSize: "11px",
								backgroundColor: "white",
								textAlign: "center",
								border: "1px solid black",
								padding: "7px",
								position: "absolute",
								zIndex: "1",
								left: "0",
								top: "110%",
							},
							'&:hover': {
								textDecoration: "underline"
							},
							'&:hover:before': {
								opacity: "1",
								visibility: "visible",
							}
						}}
					>{descriptionDisplay}</Typography>
					:
					<Typography>{descriptionDisplay}</Typography>
				}
				<Box style={{ display: "flex", flexDirection: "column" }}>
					{secondaryImgs.map(file => {

						return (
							<a href={file.content} target="_blank" rel="noreferrer">{file.name}</a>
						)
					})
					}
				</Box>
				<Box>
					<a href={primaryImg.content} target="_blank" rel="noreferrer">{primaryImg.name}</a>
				</Box>
			</Box>
			<Box style={{ display: "flex", alignItems: "center", width: "9%", justifyContent: "space-around" }}>
				<Close
					style={{ cursor: "pointer" }}
					onClick={() => {
						handleDelete(listingName, listingData)
					}}
				/>
				<Edit
					style={{ cursor: "pointer" }}
					onClick={() => {
						setIsEditing(true)
					}} />
			</Box>
		</Box>
	)
}

export default ListingEntry
