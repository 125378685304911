import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import { getFirestore, doc, setDoc, deleteDoc, onSnapshot, collection } from "firebase/firestore";
import { Helmet } from 'react-helmet'
import { Typography, Box, TextField, InputAdornment, Button, Paper } from '@mui/material'
// eslint-disable-next-line no-unused-vars
import React, { useEffect, useState, useRef } from 'react';
import { getStorage, getDownloadURL, uploadString, ref, deleteObject } from 'firebase/storage'
import getFirebase from "../components/firebaseConfig.js"
import { fetchData, filterListingsHOC, temp } from '../components/sharedFunctions.js'
import { Add } from '@mui/icons-material'
import ListingEntry from '../components/admin_panel_components/listingEntry.js'
import { css, jsx, keyframes } from '@emotion/react';
import loadingIcon from "../images/loadingIcon.png"
/** @jsx jsx */

const loadingAnimation = keyframes`
to {
  transform: rotate(360deg)
}
`

const loadingSymbolStyle = css`
  animation: ${loadingAnimation} 0.8s ease-in-out infinite;
  height:200px;
  width:200px;
`

getFirebase()
const auth = getAuth()
const db = getFirestore()
const storage = getStorage()





let localFirestoreCopy = {}

// eslint-disable-next-line no-unused-vars
const firestoreListener = onSnapshot(collection(db, "listings"), (collection) => {

	if (Object.keys(collection).length == 0) {

		collection.forEach((doc) => {


			localFirestoreCopy[doc.id] = doc.data()

		})

	}

	else {
		collection.docChanges().forEach((change) => {
			if (change.type === "added") {
				localFirestoreCopy[change.doc.id] = change.doc.data()
			}
			if (change.type === "modified") {
				localFirestoreCopy[change.doc.id] = change.doc.data()
			}
			if (change.type === "removed") {
				delete localFirestoreCopy[change.doc.id]
			}
		});
	}
});


const AdminView = (props) => {
	const [listings, setListings] = useState([])
	const [isAuthFetched, setIsAuthFetched] = useState(false)
	const [lastAddedListing, setLastAddedListing] = useState(null)
	const [isDataFetchDone, setIsDataFetchDone] = useState(false)
	const [isListingLoadDone, setIsListingLoadDone] = useState(false)
	const [isListingProcessing, setIsListingProcessing] = useState(false)
	const listingsRef = useRef()

	// eslint-disable-next-line react/prop-types
	const { nameInput, filters, filterListings, selectItemType, brandInput, deleteBrandFilter, ItemTypeEntry, BrandEntry, setNameInput, addBrand, setBrandInput } = props
	useEffect(() => {

		onAuthStateChanged(auth, (user) => {
			if (user) {
				setIsAuthFetched(true)
			}

			else {
				window.location = '/login'
			}
		})
	}, [])

	useEffect(async () => {
		fetchData(db, storage)
			.then((allListingsWithImgs) => {
				setListings(allListingsWithImgs)
				setIsDataFetchDone(true)
				listingsRef.current = allListingsWithImgs
			})
	}, [])

	useEffect(async () => {
		if (lastAddedListing != null) {
			let listing = document.getElementById(lastAddedListing)
			listing.scrollIntoView()
			window.scrollTo(0, 0)
			setIsListingProcessing(false)
		}
	}, [lastAddedListing])


	const handleValidate = (values, orgName) => {

		const errors = {}

		Object.keys(values).forEach((field) => {

			if ((!values[field] && field !== 'featured' || values[field].length === 0) && field !== 'oldPrice') {
				errors[field] = 'Missing!'
			}
		})


		if (values.price.toString().length > 7) {
			errors.price = 'Price must be 7 characters long or less'
		}

		values.secondaryImgs.forEach((img) => {

			if (img.name === values.primaryImg.name) {
				if (!errors.secondaryImgs) {
					errors.secondaryImgs = []
				}
				errors.secondaryImgs.push(img.name + ' is already used by as a primary image')
			}
		})

		let curListings = Object.keys(localFirestoreCopy)

		curListings.forEach((curListingName) => {

			let curListingPrimaryImgName = localFirestoreCopy[curListingName].primaryImgName
			let curListingSecondaryImgNames = localFirestoreCopy[curListingName].secondaryImgNames

			if (values.listingName === curListingName && values.listingName != orgName) {
				errors.listingName = 'Listing already exists'
			}


			if ((values.primaryImg.name === curListingPrimaryImgName ||
				curListingSecondaryImgNames.includes(values.primaryImg.name)) &&
				curListingName != orgName) {
				errors.primaryImg = 'Primary image already used by another listing'
			}

			let secondaryImgNames = values.secondaryImgs.map(file => { return file.name })

			secondaryImgNames.forEach((secondaryImgName) => {

				if ((curListingSecondaryImgNames.includes(secondaryImgName) || curListingPrimaryImgName === secondaryImgName) &&
					curListingName != orgName) {
					if (!errors.secondaryImgs) {
						errors.secondaryImgs = []
					}
					errors.secondaryImgs.push(secondaryImgName + ' is already used by another listing')
				}

			})


		})

		return errors
	}


	const handleDelete = async (listingName, listingData) => {




		for (let i = 0; i < listingsRef.current.length; i++) {



			if (listingsRef.current[i].listingName == listingName) {


				let listingsJSXCopy = [...listingsRef.current]

				listingsJSXCopy.splice(i, 1)


				setListings(listingsJSXCopy)

				break
			}
		}
		setLastAddedListing(null)
		await deleteDoc(doc(db, "listings", listingName))

		listingData.secondaryImgNames.forEach((name) => {
			deleteObject(ref(storage, name))
		})
		deleteObject(ref(storage, listingData.primaryImgName))
	}





	const saveChanges = async (values, orgMode, prevFileNames, prevName, setFieldValue) => {

		if (orgMode == 'add field') {
			setIsListingProcessing(true)
		}



		let curImgNames = values.secondaryImgs.map(file => { return file.name }).concat([values.primaryImg.name])

		for (let i = 0; i < prevFileNames.length; i++) {

			if (!curImgNames.includes(prevFileNames[i]) && orgMode == 'listing display') {
				await deleteObject(ref(storage, prevFileNames[i]))
			}

		}
		const numberOfSecondaryImgs = values.secondaryImgs.length


		let secondaryImgs = []

		for (let i = 0; i < numberOfSecondaryImgs; i++) {

			let img = values.secondaryImgs[i]


			let secondaryImgURL = img.content
			if (!prevFileNames.includes(img.name)) {

				let secondaryImgUpload = await uploadString(ref(storage, img.name), img.content, 'data_url')


				secondaryImgURL = await getDownloadURL(secondaryImgUpload.ref)

			}
			secondaryImgs.push({
				content: secondaryImgURL,
				name: img.name,
			})
		}
		let primaryImgUpload

		let primaryImgURL = values.primaryImg.content

		if (!prevFileNames.includes(values.primaryImg.name)) {
			primaryImgUpload = await uploadString(ref(storage, values.primaryImg.name), values.primaryImg.content, 'data_url')

			primaryImgURL = await getDownloadURL(primaryImgUpload.ref)
		}

		let primaryImg = { name: values.primaryImg.name, content: primaryImgURL }


		let listingsCopy = [...listings]


		const data = {
			brand: values.brand,
			price: values.price,
			itemType: values.itemType,
			featured: values.featured,
			oldPrice: !values.oldPrice ? null : values.oldPrice,
			description: values.description,
			primaryImgName: values.primaryImg.name,
			secondaryImgNames: values.secondaryImgs.map(file => { return file.name }),
		}


		listingsCopy.push(
			{
				listingName: values.listingName,
				data: data,
				secondaryImgs: secondaryImgs,
				primaryImg: primaryImg,
				listingJSX: (<ListingEntry
					listingData={data}

					listingName={values.listingName}

					key={values.listingName}

					secondaryImgs={secondaryImgs}

					primaryImg={primaryImg}


					handleDelete={handleDelete}

					saveChanges={saveChanges}

					handleValidate={handleValidate}

					isEditing={false}
				/>)
			})

		if (orgMode != 'listing display') {

			setListings(listingsCopy)
			setLastAddedListing(values.listingName)
			listingsRef.current = listingsCopy
		}
		else {
			setFieldValue('secondaryImgs', secondaryImgs)
			setFieldValue('primaryImg', primaryImg)

			for (let i = 0; i < listingsRef.current.length; i++) {

				if (listingsRef.current[i].listingName == prevName) {

					listingsRef.current[i].listingName = values.listingName

					break
				}
			}
		}

		setDoc(doc(db, "listings", values.listingName), data)

		if (prevName != values.listingName && orgMode == 'listing display') {
			deleteDoc(doc(db, "listings", prevName))
		}

		return { listingName: values.listingName, data: data, primaryImg: primaryImg, secondaryImgs: secondaryImgs }
	}

	if (!isAuthFetched) {
		return (<div>
		</div>)
	}


	let listingsJSX = filterListings(listings).map((listing) => {
		return (<ListingEntry
			listingData={listing.data}

			listingName={listing.listingName}

			key={listing.listingName}

			secondaryImgs={listing.secondaryImgs}

			primaryImg={listing.primaryImg}

			handleDelete={handleDelete}

			saveChanges={saveChanges}

			handleValidate={handleValidate}

			isEditing={false}
		/>)
	})

	if (isDataFetchDone && !isListingLoadDone) {
		setIsListingLoadDone(true)
	}
	return (
		<Box>
			<Helmet defer={false}>
				<title>Edit listings</title>
				<meta name="robots" content="noindex, nofollow" />
			</Helmet>
			<Box display="flex" columnGap="30px">
				<Paper width="15%" elevation={6}
					sx={{
						'&>div': {
							paddingLeft: "40px",
							paddingRight: "30px",
						}
					}}
				>
					<Typography variant="h3" marginBottom="25px" backgroundColor="#E2221D" paddingLeft="25px">Filters</Typography>
					<Box display="flex" flexDirection="column" rowGap="25px" paddingBottom="40px">
						<Box>
							<Typography variant="h4">Item name</Typography>
							<TextField
								value={nameInput}
								onChange={(e) => { setNameInput(e.target.value) }}
							/>
						</Box>
						<Box>
							<Typography variant="h4">Item Type</Typography>
							<ItemTypeEntry filterName="Accessory" category="itemType" handleChange={selectItemType} />
							<ItemTypeEntry filterName="Amplifier" category="itemType" handleChange={selectItemType} />
							<ItemTypeEntry filterName="Audio Cables" category="itemType" handleChange={selectItemType} />
							<ItemTypeEntry filterName="Bookshelf Speaker" category="itemType" handleChange={selectItemType} />
							<ItemTypeEntry filterName="Cassette Deck" category="itemType" handleChange={selectItemType} />
							<ItemTypeEntry filterName="CD Player" category="itemType" handleChange={selectItemType} />
							<ItemTypeEntry filterName="Center Speaker" category="itemType" handleChange={selectItemType} />
							<ItemTypeEntry filterName="DAC" category="itemType" handleChange={selectItemType} />
							<ItemTypeEntry filterName="Equalizer" category="itemType" handleChange={selectItemType} />
							<ItemTypeEntry filterName="Floor Standing Speaker" category="itemType" handleChange={selectItemType} />
							<ItemTypeEntry filterName="Pre-Amplifier" category="itemType" handleChange={selectItemType} />
							<ItemTypeEntry filterName="Pre-Amp & Power Amp Set" category="itemType" handleChange={selectItemType} />
							<ItemTypeEntry filterName="Power Amplifier" category="itemType" handleChange={selectItemType} />
							<ItemTypeEntry filterName="Receiver" category="itemType" handleChange={selectItemType} />
							<ItemTypeEntry filterName="Reel-to-reel Tape Recorder" category="itemType" handleChange={selectItemType} />
							<ItemTypeEntry filterName="Speaker Wires" category="itemType" handleChange={selectItemType} />
							<ItemTypeEntry filterName="Sub-Woofer" category="itemType" handleChange={selectItemType} />
							<ItemTypeEntry filterName="Tuner" category="itemType" handleChange={selectItemType} />
							<ItemTypeEntry filterName="Tuner & Amp Set" category="itemType" handleChange={selectItemType} />
							<ItemTypeEntry filterName="Turntable" category="itemType" handleChange={selectItemType} />
						</Box>
						<Box>
							<Typography variant="h4">Brand filter</Typography>
							<TextField
								placeholder="Type in brand name..."
								value={brandInput}
								onChange={(e) => {
									setBrandInput(e.target.value)
								}}
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">

											<Add style={{ cursor: "pointer" }} onClick={addBrand} />

										</InputAdornment>
									)
								}}
							/>
							<Box style={{ display: "flex", flexWrap: "wrap", marginTop: "15px", gap: "15px" }}>
								{filters.brand.map(brandName => {
									return (
										<BrandEntry brand={brandName} deleteBrandFilter={deleteBrandFilter} key={brandName} />
									)
								}
								)}
							</Box>
						</Box>
					</Box>
				</Paper>
				<Box flex={1}>
					<Box display="flex" position="relative" justifyContent="center" width="95%">
						<Typography align="center" variant="h3">Admin Panel</Typography>
						<Box position="absolute" right="0" height="100%" display="flex" columnGap="25px" alignItems="center">
							<Button variant="outlined" onClick={() => { window.location = "/" }}>Return to home page</Button>
							<Button variant="outlined" sx={{ color: "red" }} onClick={() => { signOut(auth) }}>Log out</Button>
						</Box>
					</Box>
					<Box style={{ display: "flex", position: "relative", flexDirection: "column", height: "800px", width: "95%" }}>
						{isListingLoadDone ?
							<Box position="absolute" top="0" border="1px solid black" height="800px" maxHeight="800px" width="100%" style={{ overflowY: "scroll", overflowX: "hidden" }}>
								<Box display="flex" backgroundColor="#FF1E00" position="sticky" top="0" zIndex={1} width="100%" justifyContent="flex-start" borderBottom="1px solid black">
									<Box
										sx={{
											display: "flex",
											width: "91%",
											alignItems: "center",
											'& p, span': {
												flex: 1,
												fontWeight: "bold",
												borderRight: '1px solid black',
												textAlign: "center",
											}
										}}
									>
										<Typography>Listing Name</Typography>
										<Typography>Brand</Typography>
										<Typography>Price</Typography>
										<Typography>Old Price</Typography>
										<Typography>Item Type</Typography>
										<Typography>Featured</Typography>
										<Typography>Description</Typography>
										<Typography>Secondary Images</Typography>
										<Typography>Primary Image</Typography>
									</Box>
									<Box textAlign="center" width="9%" borderRight="1px solid black">
										<Typography fontWeight="bold">Delete/Edit</Typography>
									</Box>
								</Box>
								<Box
									id="listingsDisplay">
									{listingsJSX}
								</Box>
							</Box>
							:
							null
						}
						{!isListingLoadDone ?
							<Box>
								<div style={{ width: "100%", height: "800px", alignItems: "center", display: "flex", justifyContent: "center" }}>
									<img src={loadingIcon} css={loadingSymbolStyle} />
								</div>
							</Box>
							:
							null
						}
					</Box>
					{isListingLoadDone && isListingProcessing ?
						<Box display="flex" justifyContent="center" marginTop="20px" width="95%">
							<Typography>Processing listing images...</Typography>
						</Box>
						:
						null
					}
					{isListingLoadDone ? 
						<Box style={{width:"95%"}}>
							<ListingEntry
	
								listingData={{
									brand:'',
									price:0,
									itemType:'',
									featured:false,
									description:'',
									secondaryImgNames:[],
									primaryImgName:''
								}} 

								listingName=''

								key={''}

								secondaryImgs={[]}

								primaryImg={''}

								handleDelete={handleDelete}

								saveChanges={saveChanges}

								handleValidate={handleValidate}

								isEditing={true}   
							/>
						</Box>
						:
						null
					}
				</Box>
			</Box>
			<button onClick={()=>{temp(db)}}>adjust</button>
		</Box>
				)
}

				export default filterListingsHOC(AdminView)
